import * as React from "react"

function LogoIcon() {
  return (
    <span className="anticon anticon-singlelogo ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 53 51"
      >
        <path
          fill="#38A6DE"
          d="M51.645 25.206A25.254 25.254 0 1126.394 0a25.23 25.23 0 0125.25 25.206z"
        />
        <path
          fill="#fff"
          d="M26.777 11.248a12.244 12.244 0 0112.16 9.377l.678 3.175 3.452.233a6.494 6.494 0 016.272 6.266 6.589 6.589 0 01-6.77 6.35H13.24a8.757 8.757 0 01-9.024-8.467 8.627 8.627 0 018.032-8.4l2.414-.233 1.128-2.01a12.458 12.458 0 0110.988-6.288"
        />
        <path
          fill="#18508D"
          d="M26.4 11.401a11.939 11.939 0 0111.857 9.143l.66 3.1 3.366.227a6.332 6.332 0 016.117 6.105 6.424 6.424 0 01-6.6 6.192H13.2a8.538 8.538 0 01-8.8-8.259 8.412 8.412 0 017.831-8.194l2.354-.227 1.1-1.961A12.148 12.148 0 0126.4 11.401zm0-4.128a16.648 16.648 0 00-14.631 8.336C5.148 16.272 0 21.534 0 27.909c0 6.832 5.918 12.383 13.2 12.383h28.6c6.071 0 11-4.623 11-10.32 0-5.449-4.51-9.865-10.229-10.237-1.5-7.12-8.161-12.466-16.169-12.466"
        />
      </svg>
    </span>
  )
}
export default LogoIcon
